import {
  Box,
  Button,
  Center,
  ChakraProvider,
  Flex,
  Image,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  Stepper,
  theme,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import user from '../../../assets/icons/user.png';
import background from '../../../assets/images/backgroundRegister.png';
import logo from '../../../assets/images/logoWhite.png';
import { FormOne } from './steps/formOne';
import { FormTwo } from './steps/formTwo';

const steps = [
  { title: 'First', description: 'Contact Info' },
  { title: 'Second', description: 'Date & Time' },
];

export const Register: React.FC = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState<any>(null);

  useEffect(() => {
    const userRegister = localStorage.getItem('userRegister');
    const stepNumber = localStorage.getItem('stepNumber');

    if (!formData && !userRegister && !stepNumber) {
      return;
    }

    if (!formData && userRegister && stepNumber) {
      setFormData(JSON.parse(userRegister));
      setStep(parseInt(stepNumber, 10));
    }

    if (formData && !userRegister) {
      localStorage.setItem('userRegister', JSON.stringify(formData));
    }

    if (formData && userRegister) {
      localStorage.setItem('userRegister', JSON.stringify(formData));
    }

    if (userRegister && !formData && !stepNumber) {
      setFormData(JSON.parse(userRegister));
      localStorage.setItem('stepNumber', String(step + 1));
    }

    if (stepNumber) {
      const stepInt = parseInt(stepNumber, 10);
      setStep(stepInt);
    }
  }, [formData]);

  console.log(formData);

  const handleNextStep = (data: any) => {
    const updatedFormData = { ...formData, ...data };
    setFormData(updatedFormData);
    localStorage.setItem('userRegister', JSON.stringify(updatedFormData));
    const stepNumber = step + 1;
    localStorage.setItem('step', String(stepNumber));
    setStep(stepNumber);
  };

  return (
    <Box
      minH="100vh"
      backgroundImage={background}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
    >
      <header>
        <Flex justifyContent="space-between" padding="40px">
          <Image src={logo} w="53px" h="53px" />

          <Button
            leftIcon={<Image src={user} />}
            color="#FFFFFF"
            padding="16px 27px"
            backgroundColor="#31302F"
            boxShadow="0px 15px 30px 0px rgba(0, 0, 0, 0.10)"
            onClick={() => navigate('/login')}
          >
            Fazer Login
          </Button>
        </Flex>
      </header>

      {step !== 0 && (
        <Center mb="41px">
          <ChakraProvider theme={theme}>
            <Stepper size="lg" index={step} w="20%">
              {steps.map((stepTest, index) => (
                <Step onClick={() => handleNextStep}>
                  <StepIndicator
                    w="60px"
                    h="60px"
                    bg={index < step ? '#21201F !important' : '#21201F'}
                    border={
                      index === step || index < step
                        ? '2px solid #DCAC36 !important'
                        : '0px'
                    }
                    padding="0px !important"
                    margin="0px !important"
                  >
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={
                        <StepNumber
                          style={{
                            fontSize: '30px',
                            fontWeight: '400',
                            color: '#797470',
                          }}
                        />
                      }
                      active={
                        <StepNumber
                          style={{
                            fontSize: '30px',
                            fontWeight: '400',
                            color: 'white',
                          }}
                        />
                      }
                    />
                  </StepIndicator>

                  <StepSeparator
                    style={{
                      background:
                        'linear-gradient(90deg, #DCAC36 0%, rgba(220, 172, 54, 0.00) 100%), #21201F',
                      width: '100%',
                      marginLeft: '-0.5rem',
                      marginRight: '-1rem',
                    }}
                  />
                </Step>
              ))}
            </Stepper>
          </ChakraProvider>
        </Center>
      )}

      {step === 0 && <FormOne onNextStep={handleNextStep} />}
      {step === 1 && <FormTwo formData={formData} />}
    </Box>
  );
};
